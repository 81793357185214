
* {
  box-sizing: border-box;
}
h2 {
  margin: 16px 0;
}

.balances {
  font-family: "Lucida Console",Monaco,monospace;
}

.font-weight-bold {
  font-weight: bold;
}

button {
  border: 2px solid #0072CE;
  padding: 8px;
  height: 32px;
  margin: 8px 0;
  background: white;
  border-radius: 16px;
  margin-right: 16px;
  line-height: 12px;
  cursor: pointer;
  &:hover {
    background: #ccddff;
  }
  &:active {
    color: white;
    background: #0072CE;
  }
}

.btn-success {
  border: 2px solid #00CE33;
  &:hover {
    background: #7e7;
  }
}

.container {
  padding: 16px;
  display: flex;
  justify-content: center;
}

.leaderboard {
  max-width: 220px;
  a {
    color: inherit;
    text-decoration: none;
    &:hover, &:active {
      color: #0072CE;
      text-decoration: underline;
    }
  }
  tr {
    height: 32px;
    display: inline-table;
    width: 100%;
    border-bottom: 1px solid #ddd;
    .text-nowrap {
      text-align: right;
    }
    &:hover {
      background: #ddd;
    }
  }
  .selected {
    background: #ddd;
  }
}

.row {
  display: flex;
  justify-content: center;
  width: 100%;
  > div:not(:first-child) {
    padding-left: 16px;
  }
}

@mixin block {
  width: 100%;
  max-width: 800px;
  margin-bottom: 16px;
}

.pixel-board {
  @include block;
  border: 1px solid #ddd;
}

/********************************
Layout For Tablet / Phone
********************************/

@media (max-width: 1200px) {
  .row {
    flex-direction: column;
    max-width: 800px;
    div {
      padding-left: 0 !important;
    }
  }
}

@media (max-width: 800px) {
  .pixel-board {
    width: 100vw;
    margin-left: -16px;
  }
  h2 {
    width: 100%;
    text-align: left;
  }
}



.hud {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  > p {
    display: block;
    min-width: 100px;
    margin: 8px 0;
  }
}

.float-right {
  position: absolute;
  top: 0;
  right: 0;
}

.color-picker {
  > div {
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 0 !important;
    border: none !important;
    @include block;
  }
}

.circle-picker {
  margin-top: 5px;
}









@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 0 800px;
  }
}

.c-animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #333;
  background: linear-gradient(to bottom, #333 0%, #116611 4%, #55ff55 8%, #116611 12%, #333 16%);
}
